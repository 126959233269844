<template>
  <b-container fluid class="p-5">
    <b-row>
      <b-col
        v-for="(item, index) in serviceList"
        :key="index"
        lg="4"
        md="6"
        v-b-modal.mobile-modal
      >
        <router-link :to="item.router" exact>
          <div class="mb-3 bg-grey rounded p-5 text-center" style="height: 94%">
            <b-icon :icon="item.icon" variant="info" scale="2.5"></b-icon>
            <h5 class="text-secondary my-3">{{ item.name }}</h5>
            <p
              class="text-grey"
              style="
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* number of lines to show */
                line-clamp: 2;
                -webkit-box-orient: vertical;
              "
            >
              {{ item.description }}
            </p>
          </div>
        </router-link>
      </b-col>
    </b-row>
    <VDownloadMobile></VDownloadMobile>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      serviceList: [
        {
          name: "القروض والتمويلات",
          description: "تعرف على الشروط والاليات وطرق التسديد ",
          icon: "cash-stack",
          router: "/description/loans_and_fundings",
        },
        {
          name: "التوطين",
          description:
            "هي عملية تحويل رواتب الموظفين بالقطاع الخاص او العام من رواتب يدوية يتم استلامها من دوائرهم الى ان يتم استلامها من البنوك والمصارف الحكومية والاهلية وذلك من خلال حساب خاص يفتح للموظف في المصرف ويقوم بالسحب منه بواسطة بطاقة الدفع الالكترونية او ان يستلمها نقدا من المصرف.",
          icon: "check-circle",
          router: "/description/localizations",
        },
        {
          name: "البطاقات الالكترونية",
          description: "تعرف على انواع البطاقات ومتطلبات الحصول عليها",
          icon: "credit-card",
          router: "/description/cards",
        },
        {
          name: "الحسابات المصرفية",
          description: "تعرف على أنواع الحسابات المصرفية ومتطلبات فتح الحساب",
          icon: "folder2-open",
          router: "/description/deposit_accounts",
        },
        {
          name: "التمويلات الخارجية",
          description: "الاعتمادات",
          icon: "exclude",
          router: "/description/external_fundings",
        },
        {
          name: "خطابات الضمان",
          description:
            "هو تعهد خطي  صادر من مصرف ( المصرف الكفيل او المصرف مصدر الكفالة) يتعهد بموجبه بان يدفع لأمر جهة معينة (الجهة المستفيدة) مبلغ ما خلال فترة زمنية محددة في الخطاب (مدة صلاحية الكفالة) ويأتي هذا التعهد بناءا على طلب عميله المكفول ويكون التعهد لتنفيذ عملية الدفع مرتبطا بعدم قيام المكفول بتنفيذ الشرط او الغرض او الموضوع المذكور صراحة في متن خطاب الضمان نفسه.",
          icon: "calendar2-check",
          router: "/description/letters_of_guarantees",
        },
        {
          name: "خدمات مصرفية عبر الانترنيت",
          description:
            "وهي ان يستطيع العميل بالقيام باجراء عمليات مصرفية بنفسه دون الحاجة الى مراجعه فروع المصرف حيث يمكن اجراء تحويل للمبالغ بين حسابات الزبون ,الحصول على كشف بالحركات ,معرفة الرصيد ,تحويل من حساب الزبون الى حساب زبون اخر ضمن البنك نفسه ,بلاضافة الى امكانية تقديم الطلبات مثل طلب دفتر صكوك ,طلبات فتح الحسابات وغيرها.",
          icon: "broadcast",
          router: "/description/online_services",
        },
        {
          name: "التحويل عن طريق الويسترن يونين",
          description:
            "خدمة ويسترن يونين لتحويل الاموال هي خدمة يوفرها المصرف المعني بالتعاون مع شركة ويسترن يونين التي تقوم باعمال تحويل الاموال من شخص لاخر عبر العالم .",
          icon: "award",
          router: "/description/western_unions",
        },
        {
          name: " خدمات اخرى",
          description: "",
          icon: "columns-gap",
          router: "/description/other_services",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.bg-grey {
  background-color: #edf4fc !important;
}
.col-md-6,
.col-lg-4 {
  transition: 0.3s ease !important;
}
.col-md-6:hover,
.col-lg-4:hover {
  transform: scale(1.1);
}
</style>
